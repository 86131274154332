<template>
  <q-card class="l-wrapper" flat square bordered>
    <q-banner v-if="banner" class="bg-negative text-white">
      Setiap Perubahan Password, akan berdampak juga pada aplikasi Forteapp-RnD
      <template v-slot:action>
        <q-btn flat color="white" label="Dismiss" @click="banner = false" />
      </template>
    </q-banner>
    <q-scroll-area class="l-grow">
      <div class="q-gutter-y-md q-pa-md">
        <div>
          <q-btn
            flat
            dense
            label="Back"
            icon="arrow_back"
            @click="router.go(-1)"
          ></q-btn>
        </div>
        <div class="">
          <label class="text-bold"> Nama User :</label>
          <q-input outlined readonly v-model="curUser.username"> </q-input>
        </div>
        <div class="">
          <label class="text-bold"> Password Saat Ini :</label>
          <q-input
            outlined
            ref="curpwd"
            v-model="setPwd.cur"
            :type="isPwdCur ? 'password' : 'text'"
            @keyup.enter="$refs.newpwd.focus()"
            ><template v-slot:append>
              <q-icon
                :name="isPwdCur ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwdCur = !isPwdCur"
              /> </template
          ></q-input>
        </div>
        <div class="">
          <label class="text-bold"> Password Baru :</label>
          <q-input
            outlined
            ref="newpwd"
            v-model="setPwd.new"
            :type="isPwdNew ? 'password' : 'text'"
            @keyup.enter="$refs.confpwd.focus()"
            ><template v-slot:append>
              <q-icon
                :name="isPwdNew ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwdNew = !isPwdNew"
              /> </template
          ></q-input>
        </div>
        <div class="">
          <label class="text-bold"> Konfirmasi Password Baru :</label>
          <q-input
            outlined
            ref="confpwd"
            v-model="setPwd.conf"
            :type="isPwdConf ? 'password' : 'text'"
            @keyup.enter="checkPassword"
            ><template v-slot:append>
              <q-icon
                :name="isPwdConf ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwdConf = !isPwdConf"
              /> </template
          ></q-input>
        </div>
      </div>
    </q-scroll-area>
    <q-card-actions class="q-pa-none" align="stretch">
      <q-btn
        unelevated
        type="submit"
        color="primary"
        class="full-width no-border-radius"
        label="GANTI PASSWORD"
        @click="checkPassword"
      ></q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import { computed, reactive, ref, inject, onMounted } from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useQuasar } from "quasar";
import { useStore } from "vuex";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const $q = useQuasar();
    let $http = inject("$http");
    const store = useStore();
    let curUser = computed(() => store.state.user);

    onMounted(async () => {});

    let setPwd = ref({
      cur: "",
      new: "",
      conf: "",
    });

    let curpwd = ref(null);
    let newpwd = ref(null);
    let confpwd = ref(null);

    let checkPassword = async () => {
      if (setPwd.value.cur == "") {
        curpwd.value.focus();
        $q.notify({
          message: "Password Saat Ini tidak boleh kosong",
          color: "negative",
        });
        return;
      }
      if (setPwd.value.new == "") {
        newpwd.value.focus();
        $q.notify({
          message: "Password Baru tidak boleh kosong",
          color: "negative",
        });
        return;
      }
      if (setPwd.value.conf != setPwd.value.new) {
        confpwd.value.focus();
        $q.notify({
          message: "Konfirmasi Password tidak sama dengan Password Baru",
          color: "negative",
        });
        return;
      }

      try {
        const MD5 = require("md5");
        let payload = {
          username: curUser.value.username,
          password: MD5(setPwd.value.cur),
        };
        let resp = await $http.post("/login", payload);
        if (resp.data) {
          await saveNewPassword();
        }
      } catch (error) {
        $q.notify({ message: "Password Lama Salah", color: "negative" });
        curpwd.value.focus();
      }
    };

    let saveNewPassword = async () => {
      try {
        const MD5 = require("md5");
        let payload = {
          id: curUser.value.id,
          cur_password: MD5(setPwd.value.cur),
          new_password: MD5(setPwd.value.new),
        };
        await $http.post(`/edit_password`, payload);
        $q.notify({ message: "Password Berhasil Dirubah", color: "positive" });
      } catch (err) {
        $q.notify({ message: "Password Gagal Dirubah", color: "negative" });
      }
      setPwd.value.cur = "";
      setPwd.value.new = "";
      setPwd.value.conf = "";
    };

    return {
      banner: ref(true),
      isPwdCur: ref(true),
      isPwdNew: ref(true),
      isPwdConf: ref(true),
      route,
      router,
      curUser,
      setPwd,
      checkPassword,
      curpwd,
      newpwd,
      confpwd,
    };
  },
};
</script>
